var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _vm.rop !== "new"
                  ? _c("asyent-form-info", {
                      attrs: {
                        "no-title": "",
                        color: "cardPrimary",
                        "no-maximize": ""
                      },
                      model: {
                        value: _vm.formData,
                        callback: function($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Code",
                                        name: "code",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["code"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "code", $$v)
                                        },
                                        expression: "formData['code']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Name",
                                        name: "name",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["name"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "name", $$v)
                                        },
                                        expression: "formData['name']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.policiesReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        label: "Password Policy",
                                        mandatory: "",
                                        name: "passwordPolicy",
                                        "show-item-code": "",
                                        sortable: "",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["passwordPolicy"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "passwordPolicy",
                                            $$v
                                          )
                                        },
                                        expression: "formData['passwordPolicy']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.groupsReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        label: "Parent Group",
                                        mandatory: "",
                                        name: "parentGroup",
                                        "show-item-code": "",
                                        sortable: "",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["parentGroup"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "parentGroup",
                                            $$v
                                          )
                                        },
                                        expression: "formData['parentGroup']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.statusReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "Group Status",
                                        mandatory: "",
                                        name: "status",
                                        sortable: "",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["status"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "status", $$v)
                                        },
                                        expression: "formData['status']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("asyent-form-select", {
                          attrs: {
                            "form-errors": _vm.formErrors,
                            "form-rules": _vm.formFieldsRules,
                            "is-disabled-func": _vm.isDisabled,
                            "items-reload": _vm.unitsReload,
                            "items-reload-params": _vm.formData.parentGroup,
                            props: props,
                            "enable-select-all": "",
                            label: "Access Units",
                            multiple: "",
                            name: "units",
                            sortable: "",
                            title: "Access Units",
                            "view-mode": _vm.viewMode
                          },
                          model: {
                            value: _vm.formData["units"],
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "units", $$v)
                            },
                            expression: "formData['units']"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Remarks", expandable: "" } },
                          [
                            _c("asyent-form-textarea", {
                              attrs: {
                                "form-errors": _vm.formErrors,
                                "form-rules": _vm.formFieldsRules,
                                "is-disabled-func": _vm.isDisabled,
                                props: props,
                                flat: "",
                                label: "User Group Remarks",
                                "no-label": "",
                                name: "remarks",
                                "no-resize": "",
                                rows: 9,
                                "view-mode": _vm.viewMode
                              },
                              model: {
                                value: _vm.formData["remarks"],
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "remarks", $$v)
                                },
                                expression: "formData['remarks']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }